exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-databasewarga-js": () => import("./../../../src/pages/databasewarga.js" /* webpackChunkName: "component---src-pages-databasewarga-js" */),
  "component---src-pages-galeri-foto-category-template-js": () => import("./../../../src/pages/galeri_foto/category-template.js" /* webpackChunkName: "component---src-pages-galeri-foto-category-template-js" */),
  "component---src-pages-galeri-foto-index-js": () => import("./../../../src/pages/galeri_foto/index.js" /* webpackChunkName: "component---src-pages-galeri-foto-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/info/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-info-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-kegiatan-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/kegiatan/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-kegiatan-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-njajah-desa-js": () => import("./../../../src/pages/njajah_desa.js" /* webpackChunkName: "component---src-pages-njajah-desa-js" */),
  "component---src-pages-pariwisata-index-js": () => import("./../../../src/pages/pariwisata/index.js" /* webpackChunkName: "component---src-pages-pariwisata-index-js" */),
  "component---src-pages-pariwisata-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/pariwisata/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-pariwisata-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-produk-kami-batik-ecoprint-js": () => import("./../../../src/pages/produk_kami/batik_ecoprint.js" /* webpackChunkName: "component---src-pages-produk-kami-batik-ecoprint-js" */),
  "component---src-pages-produk-kami-index-js": () => import("./../../../src/pages/produk_kami/index.js" /* webpackChunkName: "component---src-pages-produk-kami-index-js" */),
  "component---src-pages-produk-kami-kelompok-ternak-js": () => import("./../../../src/pages/produk_kami/kelompok_ternak.js" /* webpackChunkName: "component---src-pages-produk-kami-kelompok-ternak-js" */),
  "component---src-pages-produk-kami-rumah-kompos-js": () => import("./../../../src/pages/produk_kami/rumah_kompos.js" /* webpackChunkName: "component---src-pages-produk-kami-rumah-kompos-js" */)
}

